import * as React from "react";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from "src/utils/axios";

import useSettings from "src/hooks/useSettings";
// routes

// components
import Page from "src/components/Page";
import KnowledgeBaseEmpty from "src/sections/knowledgebases/empty";
import { Container } from "@mui/material";
import { Card, CardContent, CardActions, Typography, Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { TrendingDown } from "@mui/icons-material";
import CreateNewKknowledgeBaseModal from "src/components/modals/knowledge-base/CreateNewKnowledgeBaseModal";
import BarChartIcon from "@mui/icons-material/BarChart";
import RecommendIcon from "@mui/icons-material/Recommend";
import ChartModal from "src/components/modals/ChartModal";

const columns1 = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "last_edited", label: "Last Edited", minWidth: 200 },
  { id: "action", label: "Action", minWidth: 150 },
];

const sections1 = ["name", "updated_at"];

const sortOptions = [
  { key: 'newest', label: 'Sort by: Newest', value: 'newest' },
  { key: 'popular', label: 'Sort by: Popular', value: 'popular' },
];

const MenuProps = {
  disableScrollLock: true,
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
    },
  },
};

export default function KnowledgeBases() {
  const { themeStretch } = useSettings();
  const [kBPage, setKBPage] = React.useState(0);
  const [overAll, setOverAll] = React.useState(0);
  const [overAllUsageCnt, setOverAllUsageCnt] = React.useState(0);
  const [successCnt, setSuccessCnt] = React.useState(0);
  const [rowsPerKBPage, setRowsPerKBPage] = React.useState(10);
  const [openNewKnowledgeBaseModal, setOpenNewKnowledgeBaseModal] =
    React.useState(false);
  const [showChartModal, setShowChartModal] = React.useState(false);
  const [knowledgeBases, setKnowledgeBases] = React.useState([]);
  const [overAllKnowledgeBases, setOverAllKnowledgeBases] = React.useState([]);
  const [overAllUsage, setOverAllUsage] = React.useState([]);
  const [successResult, setSuccessResult] = React.useState([]);
  const [chartNumber, setChartNumber] = React.useState(0);
  const [title, setTitle] = React.useState("");
  const [sortBy, setSortBy] = React.useState('newest');

  const [knowledgeBase, setKnowledgeBase] = React.useState({
    userId: jwtDecode(localStorage.getItem("accessToken")).user_id,
  });

  const navigate = useNavigate();

  React.useEffect(() => {
    axios
      .get(
        "/api/v1/knowledgebases/user/" +
        jwtDecode(localStorage.accessToken).user_id
      )
      .then((result) => {
        setKnowledgeBases([...result.data.knowledgeBases]);
        setOverAll(result.data.overAll);
        setOverAllUsageCnt(result.data.overallUsage ? result.data.overallUsage : 0);
        setSuccessCnt(result.data.overallSuccess);
      })
      .catch((err) => {
        console.log("error is", err);
      });

    axios
      .get("/api/v1/knowledgebases/overall")
      .then((res) => {
        setOverAllKnowledgeBases([...res.data]);
      })
      .catch((err) => {
        console.log("Getting Overall Agents Error is ", err);
      });

    axios
      .get("/api/v1/knowledgebases/overall_usage/")
      .then((res) => {
        setOverAllUsage([...res.data]);
      })
      .catch((err) => {
        console.log("Getting Overall Usage Error is ", err);
      });
  }, []);

  const handleOpenNewKnowledgeBaseModal = () =>
    setOpenNewKnowledgeBaseModal(true);

  const handleChangeKBPage = (event, newPage) => {
    setKBPage(newPage);
  };

  const handleChangeRowsPerKBPage = (event) => {
    setRowsPerKBPage(event.target.value);
    setKBPage(0);
  };

  const handleDuplicateKnowledgeBase = (e, data) => {
    e.stopPropagation();

    axios
      .post("/api/v1/knowledgebases/create/", {
        userId: data.userId,
        name: "[COPY] " + data.name,
      })
      .then((result) => {
        setKnowledgeBases([...knowledgeBases, result.data]);
      })
      .catch((err) => {
        console.log("error is ", err);
      });
  };

  const handleDeleteKnowledgeBase = (e, knowledgeBase) => {
    e.stopPropagation();

    const prevAgents = knowledgeBases;
    axios
      .delete(`/api/v1/knowledgebases/delete/${knowledgeBase.id}`)
      .then((response) => {
        setKnowledgeBases(() => {
          return prevAgents.filter((item) => item.id !== knowledgeBase.id);
        });
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };

  const hendleEditKnowledgeBase = (knowledgeBase) => {
    navigate("/knowledge-bases/edit/" + knowledgeBase.id);
  };

  const check_rising = (data) => {
    const total = data.reduce((accumulator, currentValue) => {
      return Number(accumulator) + Number(currentValue);
    }, 0);

    const cur_month = data[Number(new Date().getMonth())];
    const mean = (total - cur_month) / Number(new Date().getMonth());

    return cur_month > mean;
  };

  const calc_rising = (data) => {
    const total = data.reduce((accumulator, currentValue) => {
      return Number(accumulator) + Number(currentValue);
    }, 0);

    const cur_month = data[Number(new Date().getMonth())];
    const mean = (total - cur_month) / Number(new Date().getMonth());

    return ((Math.abs(cur_month - mean) / mean) * 100).toFixed(2) + "%";
  };

  const OverAllKnowledgeBaseCard = (
    <React.Fragment>
      <CardContent
        sx={{ display: "flex", alignItems: "center", width: "100%", gap: 8 }}
      >
        <Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Overall Knowlede Base
          </Typography>
          <Typography variant="h5" component="div">
            {overAll}
          </Typography>
        </Typography>
        <Typography sx={{ ml: "auto" }}>
          <BarChartIcon />
        </Typography>
      </CardContent>
      <CardActions
        sx={{ mx: 2, borderTop: "1px solid #dddddd", display: "flex" }}
      >
        {check_rising(overAllKnowledgeBases) ? (
          <Stack
            border={"solid 1px"}
            p={"1px"}
            sx={{
              borderRadius: "20%",
              background: "rgba(57, 181, 74, .2)",
              color: "#39B54A",
            }}
          >
            <TrendingUpIcon color="text.secondary" />
          </Stack>
        ) : (
          <Stack
            border={"solid 1px"}
            p={"1px"}
            sx={{
              borderRadius: "20%",
              background: "rgba(181, 57, 57, .2)",
              color: "#B53939",
            }}
          >
            <TrendingDown color="text.secondary" />
          </Stack>
        )}
        <Stack>{calc_rising(overAllKnowledgeBases)}</Stack>
        <Button
          sx={{ marginLeft: "auto !important", marginRight: "0" }}
          onClick={(e) => handleOverAllKnowledgeBases()}
        >
          View More
        </Button>
      </CardActions>
    </React.Fragment>
  );
  const OverAllUsageCard = (
    <React.Fragment>
      <CardContent
        sx={{ display: "flex", alignItems: "center", width: "100%", gap: 8 }}
      >
        <Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Overall Usage
          </Typography>
          <Typography variant="h5" component="div">
            {overAllUsageCnt}
          </Typography>
        </Typography>
        <Typography sx={{ ml: "auto" }}>
          <BarChartIcon />
        </Typography>
      </CardContent>
      <CardActions
        sx={{ mx: 2, borderTop: "1px solid #dddddd", display: "flex" }}
      >
        {check_rising(overAllUsage) ? (
          <Stack
            border={"solid 1px"}
            p={"1px"}
            sx={{
              borderRadius: "20%",
              background: "rgba(57, 181, 74, .2)",
              color: "#39B54A",
            }}
          >
            <TrendingUpIcon color="text.secondary" />
          </Stack>
        ) : (
          <Stack
            border={"solid 1px"}
            p={"1px"}
            sx={{
              borderRadius: "20%",
              background: "rgba(181, 57, 57, .2)",
              color: "#B53939",
            }}
          >
            <TrendingDown color="text.secondary" />
          </Stack>
        )}
        <Stack>{calc_rising(overAllUsage)}</Stack>
        <Button
          sx={{ marginLeft: "auto !important", marginRight: "0" }}
          onClick={(e) => handleOverAllUsage()}
        >
          View More
        </Button>
      </CardActions>
    </React.Fragment>
  );
  const SuccessfulResultCard = (
    <React.Fragment>
      <CardContent
        sx={{ display: "flex", alignItems: "center", width: "100%", gap: 8 }}
      >
        <Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Successful Result
          </Typography>
          <Typography variant="h5" component="div">
            {(overAllUsageCnt
              ? ((successCnt / overAllUsageCnt) * 100).toFixed(2)
              : "0.00") + "%"}
          </Typography>
        </Typography>
        <Typography sx={{ ml: "auto" }}>
          <RecommendIcon />
        </Typography>
      </CardContent>
      <CardActions
        sx={{ mx: 2, borderTop: "1px solid #dddddd", display: "flex" }}
      >
        <Button
          sx={{ marginLeft: "auto", marginRight: "0" }}
          onClick={(e) => handleSuccessChart()}
        >
          View More
        </Button>
      </CardActions>
    </React.Fragment>
  );

  const handleOverAllKnowledgeBases = () => {
    setChartNumber(0);
    setTitle("Overview of Over All Knowledge Bases");
    setShowChartModal(true);
  };

  const handleOverAllUsage = () => {
    setChartNumber(1);
    setTitle("Overview of Over All Usage");
    setShowChartModal(true);
  };

  const handleSuccessChart = () => {
    setChartNumber(2);
    setTitle("Overview of Success Result");
    setSuccessResult([
      { id: 0, value: successCnt, label: "Success", color: "green" },
      {
        id: 1,
        value: overAllUsageCnt - successCnt,
        label: "Failed",
        color: "red",
      },
    ]);
    setShowChartModal(true);
  };

  const handleChange = (event) => {
    setSortBy(event.target.value)
  };

  return (
    <Page title="Knowledge Bases">
      <Stack
        sx={{
          px: 3,
          gap: '20px',
          flexDirection: {
            xs: 'column',
            md: 'row'
          },
        }}
      >
        <Stack>
          <Typography sx={{ color: '#0053A7', fontWeight: '600', fontSize: '20px' }}>Knowledge Base</Typography>
          <Typography sx={{ fontSize: '12px' }}><i>Manage and review your existing knowledge base here.</i></Typography>
        </Stack>

        <Stack
          direction='row'
          sx={{
            justifyContent: 'space-between',
            gap: '30px',
            alignItems: 'center',
            marginLeft: {
              xs: '0',
              md: 'auto'
            },
            marginRight: '0',
          }}>
          <FormControl sx={{ width: "100%" }} size="small">
            <InputLabel id="demo-select-small-label">Sort By</InputLabel>
            <Select
              label="Sort By"
              onChange={handleChange}
              value={sortBy}
              sx={{ fontSize: '15px !important' }}
              MenuProps={MenuProps}
            >
              {sortOptions.map((option) => (
                <MenuItem key={option.key} value={option.value} sx={{ fontSize: '15px !important' }}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LoadingButton
            fullWidth
            size="medium"
            variant="contained"
            sx={{ whiteSpace: 'nowrap' }}
            onClick={handleOpenNewKnowledgeBaseModal}
          >
            Create Knowledge Base
          </LoadingButton>
        </Stack>
      </Stack>
      <Box
        display={"flex"}
        width={"100%"}
        gap={4}
        justifyContent="space-between"
        sx={{ px: 3, flexWrap: "wrap", mt: 5, mb: 5 }}
      >
        <Card
          sx={{
            width: {
              xs: "100%",
              sm: "30%",
            },
          }}
        >
          {OverAllKnowledgeBaseCard}
        </Card>
        <Card
          sx={{
            width: {
              xs: "100%",
              sm: "30%",
            },
          }}
        >
          {OverAllUsageCard}
        </Card>
        <Card
          sx={{
            width: {
              xs: "100%",
              sm: "30%",
            },
          }}
        >
          {SuccessfulResultCard}
        </Card>
      </Box>
      {knowledgeBases.length ? (
        <Container maxWidth={themeStretch ? false : "lg"}>
          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              border: "1px solid rgba(65,65,65, 0.13)",
              borderRadius: "10px",
              p: 3,
            }}
          >
            <TableContainer sx={{ maxHeight: 800 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns1.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        sx={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {knowledgeBases.length
                    ? knowledgeBases
                      .slice(
                        kBPage * rowsPerKBPage,
                        kBPage * rowsPerKBPage + rowsPerKBPage
                      )
                      .map((row, index) => {
                        return (
                          <TableRow
                            hover
                            key={row.companyName + index}
                            onClick={(e) => hendleEditKnowledgeBase(row)}
                          >
                            {sections1.map((column, index1) => {
                              const value = row[column];
                              return (
                                <TableCell
                                  key={column + index1}
                                  align={column.align}
                                >
                                  {column !== "updated_at"
                                    ? value
                                    : new Date(value).toLocaleString(
                                      "en-GB",
                                      {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      }
                                    )}
                                </TableCell>
                              );
                            })}
                            <TableCell>
                              <IconButton
                                aria-label="duplicate"
                                onClick={(e) =>
                                  handleDuplicateKnowledgeBase(e, row)
                                }
                              >
                                <ContentCopyIcon />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                onClick={(e) =>
                                  handleDeleteKnowledgeBase(e, row)
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={knowledgeBases.length}
              rowsPerPage={rowsPerKBPage}
              page={kBPage}
              onPageChange={handleChangeKBPage}
              onRowsPerPageChange={handleChangeRowsPerKBPage}
            />
          </Paper>
        </Container>
      ) : (
        <KnowledgeBaseEmpty
          setOpenNewKnowledgeBaseModal={setOpenNewKnowledgeBaseModal}
        />
      )}
      <CreateNewKknowledgeBaseModal
        openNewKnowledgeBaseModal={openNewKnowledgeBaseModal}
        setOpenNewKnowledgeBaseModal={setOpenNewKnowledgeBaseModal}
        knowledgeBase={knowledgeBase}
        knowledgeBases={knowledgeBases}
        setKnowledgeBase={setKnowledgeBase}
        setKnowledgeBases={setKnowledgeBases}
      />

      <ChartModal
        modalShow={showChartModal}
        setModalShow={setShowChartModal}
        data={
          chartNumber
            ? chartNumber === 1
              ? overAllUsage
              : successResult
            : overAllKnowledgeBases
        }
        title={title}
        isLegend={chartNumber === 2}
      />
    </Page>
  );
}
