import { Stack, Typography } from "@mui/material"
import Page from "src/components/Page"

export default function ComingSoonPage() {
    return (
        <Page title="Coming Soon">
            <Stack sx={{ justifyContent: 'center', alignItems: 'center', height: '50vh', }}>
                <img src="/assets/coming_soon.png" alt="coming soon" width='300px' />
                <Typography sx={{ maxWidth: '300px', textAlign: 'center', fontSize: '20px', mt: 3 }}>
                    We&apos;re still working on this.
                    Please check back later.
                </Typography>
            </Stack>
        </Page>
    )
}