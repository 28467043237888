import { Grid } from "@mui/material";
import Page from "src/components/Page";
import RecentBlogs from "./Recent";
import { useParams } from "react-router";
import BlogCard from "src/components/cards/BlogCard";
import { blogs } from "src/utils/constants";

export default function BlogDetailPage() {
    const { id } = useParams();

    return (
        <Page title="Omodore Blog Detail Page">
            <Grid container sx={{ px: { xs: 1, sm: 10 } }}>
                <Grid item xs={12} sm={8}>
                    <BlogCard blog={blogs[id]} isActive={true} indexNumber={id} />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <RecentBlogs blogs={blogs} />
                </Grid>
            </Grid>
        </Page>
    )
}