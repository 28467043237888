import * as React from "react";
import Page from "src/components/Page";
import { useNavigate } from "react-router";
import useSettings from "src/hooks/useSettings";
import Image from "src/components/Image";
import {
    Paper,
    Stack,
    Box,
    Typography,
    IconButton
} from "@mui/material";
import { useSnackbar } from "notistack";

export default function FeaturesPage() {
    const { enqueueSnackbar } = useSnackbar();
    const { themeStretch } = useSettings();

    return (
        <Page title="Omodore Features" height={"100%"}>
            <Paper
                sx={{
                    width: "100%",
                    overflow: "hidden",
                    borderRadius: "10px",
                    p: 3,
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '50px',
                    mx: 'auto',
                }}
            >
                <Image src="/assets/features/ellipse_1.png" alt="BG Ellipse" sx={{
                    position: 'absolute',
                    zIndex: '1',
                    width: '100%',
                    mt: { xs: '100px', md: '-250px' },
                }} />
                <Stack sx={{ justifyContent: 'center', alignItems: 'center', gap: '30px', textAlign: 'center', maxWidth: '1001px', zIndex: 1 }}>
                    <Typography sx={{
                        fontSize: {
                            xs: '24px',
                            md: '40px'
                        },
                        fontWeight: '800',
                        maxWidth: '938px',
                        lineHeight: {
                            xs: '32px',
                            md: '42px'
                        },
                    }}>
                        Features of Omodore AI: Transforming Customer Service with Innovation
                    </Typography>
                    <Typography>
                        Welcome to the future of customer engagement! Omodore AI is your partner in delivering seamless customer experiences, designed to improve efficiency and drive growth. Explore the powerful features of Omodore AI, built to empower businesses across industries like healthcare, e-commerce, hospitality, telecommunications, and more.
                    </Typography>
                </Stack>
                <Stack sx={{ width: '100%', alignItems: 'center', zIndex: 2 }}>
                    {
                        features.map((feature, index) => {
                            return (
                                <FeatureComponent feature={feature} key={`feature-${index}`} imageFirst={index % 2} />
                            )
                        })
                    }
                </Stack>

                <Stack sx={{
                    maxWidth: '705px',
                    borderRadius: '10px',
                    p: '40px',
                    boxShadow: '0px 4px 10px 0px #3EB54B33',
                    textAlign: 'center',
                    position: 'relative',
                    mb: '50px'
                }}>
                    <Typography sx={{
                        fontSize: {
                            xs: '20px',
                            md: '30px'
                        }
                        ,
                        lineHeight: '42px',
                        fontWeight: '800',
                        mb: 2
                    }}>
                        Why Choose Omodore?
                    </Typography>
                    <Typography sx={{ fontSize: { xs: '14px', md: '16px' }, lineHeight: '23px' }}>
                        Omodore combines advanced AI technology with easy-to-use tools to transform the way you engage with customers. Whether you�re looking to increase sales, improve efficiency, or create outstanding customer experiences, our solutions are here to help your business succeed.
                    </Typography>
                    <Typography sx={{ fontSize: { xs: '14px', md: '18px' }, lineHeight: { xs: '19px', md: '42px' }, fontWeight: '800' }}>
                        Discover the difference Omodore can make for your business today.
                    </Typography>
                    <IconButton
                        color="primary"
                        sx={{
                            borderRadius: "50%",
                            width: { xs: '78px', md: "97px" },
                            height: { xs: '78px', md: "97px" },
                            padding: '20px',
                            backgroundColor: "#3EB54B",
                            "&:hover": {
                                backgroundColor: "#007B55",
                            },
                            color: "white",
                            position: 'absolute',
                            bottom: { xs: '-60px', md: '-70px' }
                        }}
                    >
                        <Image src="/assets/icons/chatbot.png" alt="AI Chat Bot" />
                    </IconButton>
                </Stack>
            </Paper>
        </Page >
    );
}

export const FeatureComponent = ({ feature, imageFirst }) => {
    return (
        <Stack
            sx={{
                justifyContent: 'center',
                my: 5,
                width: '100%',
                flexDirection: {
                    xs: 'column',
                    md: 'row'
                },
                gap: '30px'
            }}
        >
            <Stack
                sx={{
                    maxWidth: imageFirst ? '840px' : '780px',
                    gap: '20px',
                    order: {
                        xs: 1,
                        md: imageFirst ? 2 : 1
                    },
                    justifyContent: 'center'
                }}
            >
                <Typography sx={{
                    color: '#3EB54B',
                    fontSize: {
                        xs: '20px',
                        md: '24px'
                    },
                    fontWeight: '800',
                    textAlign: {
                        xs: 'center',
                        md: imageFirst ? 'right' : 'left'
                    },
                }}>
                    {feature?.title}
                </Typography>
                <Typography sx={{
                    textAlign: {
                        xs: 'left',
                        md: imageFirst ? 'right' : 'left'
                    },
                    fontSize: '16px',
                    lineHeight: '23px'
                }}>
                    {feature?.content}
                </Typography>
            </Stack>
            <Box
                component="img"
                src={feature?.image}
                alt={feature?.title}
                sx={{
                    order: {
                        xs: 2,
                        md: imageFirst ? 1 : 2
                    },
                    maxWidth: '100%',
                    height: 'auto'
                }}
            />
        </Stack>
    );
}


export const features = [
    {
        title: "AI-Powered Chat Assistants",
        content: "Make customer interactions smoother with advanced AI chat assistants. These tools respond instantly to customer queries, ensuring every interaction is efficient. From answering FAQs to handling complex inquiries, Omodore�s chat assistants are here to support your business 24/7.",
        image: "/assets/features/chat_assistant.png",
    },
    {
        title: "Smart Lead Generation",
        content: "Connect with potential customers like never before. Capture up to 80% of lead contact information automatically, ensuring you never miss an opportunity. Reach customers wherever they are and follow up effectively, helping to boost your conversion rates with ease.",
        image: "/assets/features/smart_lead.png",
    },
    {
        title: "Chat Marketing for Sales Growth",
        content: "Change the way you approach sales with AI-driven chat marketing. Engage customers in real-time conversations that feel natural and help grow your sales without extra hassle. By cutting down on expensive retargeting campaigns, Omodore helps you achieve better results while saving time and money.",
        image: "/assets/features/chat_marketing.png",
    },
    {
        title: "Instant Response Automation",
        content: "Don�t let a single message go unanswered. With Omodore, every mention, reaction, or inquiry gets an immediate reply. Speed up interactions and keep your customers happy while staying ahead in a competitive market.",
        image: "/assets/features/response_automation.png",
    },
    {
        title: "Customizable AI Agents",
        content: "Adapt your AI agents to reflect your brand�s voice and meet your business needs. Choose from pre-designed agents made for industries like e-commerce, healthcare, and customer service. Personalize their responses, tone, and functionality to create unforgettable customer experiences.",
        image: "/assets/features/customizable.png",
    },
    {
        title: "Analytics and Performance Insights",
        content: "Keep track of your AI tools� impact with detailed analytics. Monitor user interactions, measure success, and see how your agents improve customer satisfaction and business efficiency.",
        image: "/assets/features/analytics.png",
    },
    {
        title: "Easy Setup and No Experience Needed",
        content: "Getting started is simple. Set up your first automation with plug-and-play templates or Omodore�s AI Assistant. No technical knowledge is required, just a few clicks, and your AI solutions are ready to go.",
        image: "/assets/features/easy_setup.png",
    },
    {
        title: "Cost-Effective Solutions",
        content: "Save time and reduce costs by automating routine tasks. From managing customer inquiries to streamlining communications, Omodore lets you focus on growing your business without overextending your resources.",
        image: "/assets/features/cost_effective.png",
    },
    {
        title: "Dedicated Support and Troubleshooting",
        content: "We�re here to help whenever you need it. Explore our extensive support resources, find quick solutions to common problems, or reach out to our friendly customer support  team through live chat, email, or phone.",
        image: "/assets/features/support.png",
    },
]