import * as React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Box, Button, Stack } from '@mui/material';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import axios from 'src/utils/axios';
import { useNavigate } from 'react-router-dom';

export default function CreateScript({ agentData, setAgentData }) {
    // const [agentData, setAgentData] = React.useState(() => {
    //     const storedAgent = localStorage.getItem('agent');
    //     return storedAgent ? JSON.parse(storedAgent) : null;
    // });
    const navigate = useNavigate();

    if (agentData === null) {
        return null;
    }

    const initialContent = agentData.desc;
    const contentBlock = htmlToDraft(initialContent);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    const [editorState, setEditorState] = React.useState(EditorState.createWithContent(contentState));

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setAgentData({
            ...agentData,
            desc: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        })
    }

    const saveAgent = () => {
        localStorage.removeItem('agent');
        axios
            .post('/api/v1/agents/create/', agentData)
            .then(result => {
                navigate('/agents');
            })
    }

    return (
        <Box sx={{ mt: 5 }}>
            <Stack direction={'row'} justifyContent='flex-end' gap="10px" sx={{ mb: 2 }}>
                <Button variant='outlined' onClick={saveAgent}>Save</Button>
            </Stack>
            <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
            />
        </Box >
    );
}