import BlogCard from "src/components/cards/BlogCard"
import { Stack } from "@mui/material"

export default function RecentBlogs({ blogs }) {
    return (
        <Stack>
            {
                blogs.map((blog, index) => (
                    <BlogCard blog={blog} key={`blog-${index}`} indexNumber={index} />
                ))
            }
        </Stack>
    )
}